.header_container {
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100vw;
  padding: 18px 20px;
  box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px;
  background: #FFFFFF;
  z-index: 10;
}

.logo_container {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    margin-left: 4px;

    font-family: Roboto, sans-serif;
    font-size: 17.12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.header_button {
  display: flex;
  padding: 11px 26px;
  justify-content: center;
  align-items: center;
  border-radius: 24.081px;
  background: #3177F2;
  border: none;
  outline: none;

  color: #FFF;
  font-family: Roboto, sans-serif;
  font-size: 14.478px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.199px;
}