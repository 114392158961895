.title_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 17px;

  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 45px;

  span {
    color: #3177F2;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 45px;

    @media screen  and (max-width: 375px) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  @media screen  and (max-width: 375px) {
    font-size: 24px;
    line-height: 30px;
  }
}

.image_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_banner {
  //width: 100%;
  max-width: 430px;
  //height: 200px;
}

.button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 25px;
}

.main_screen_button {
  max-width: calc(100% - 30px);
  border-radius: 15.419px;
  background: #FF5D17;
  border: none;
  outline: none;
  padding: 20px 32px;

  color: #FFF;
  font-size: 24.835px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.64px;

  @media screen  and (max-width: 375px) {
    padding: 15px 20px;
    font-size: 20.835px;
  }
}

.icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 23px;
}

.advantages_container {
  margin: 23px auto 0 auto;
  padding: 20px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 32px);
  height: 70px;
  border-radius: 13px;
  box-shadow: 0px 2.087px 11.061px 0px rgba(0, 0, 0, 0.25);
}

.span_number {
  color: #2B2B2B;
  font-family: "Roboto", sans-serif;
  font-size: 18.346px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.span_description {
  color: #646464;
  font-family: "Roboto", sans-serif;
  font-size: 13.759px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}