* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
  width: 100vw;
}

a {
  text-decoration: none;
  color: inherit;
}
