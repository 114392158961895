.solutions_container {
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h2 {
    color: #FF5D17;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 29px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    text-transform: uppercase;
  }

  h3 {
    color: #3177F2;
    font-family: "Poppins", sans-serif;
    font-size: 29px;
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    text-transform: uppercase;
  }

  p {
    margin-top: 10px;
    font-family: "Poppins", sans-serif;
    color: #626262;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.solution_icons_wrapper {
  display: flex;
  align-items: center;
  gap: 13px;
}