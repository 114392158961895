.footer_container {
  margin: 0 auto;
  width: calc(100% - 20px);
  padding: 13px 0px;
  border-bottom: 1px solid rgba(80, 80, 80, 0.50);
}

.footer_title {
  color: #646464;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.store_icons_container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 13px;

  img {
    //width: 100%;
    height: 42px;
  }
}

.footer_description {
  margin-top: 13px;
  text-align: center;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  a {
    color: #5072F1;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-decoration-line: underline;
  }
}

.footer_copyright {
  margin: 13px 0px;
  color: #868686;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}