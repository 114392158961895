.reviews_container {
  margin-top: 19px;
  text-align: center;
}

.reviews_title {
  margin: 0 auto 37px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 64px;
  border-radius: 16px;
  background: #3177F2;

  color: #FFF;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.reviews_item_container {
  margin: 0 auto 14px auto;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 19px 20px;

  width: calc(100% - 20px);
  border-radius: 22px;
  background: #FFF;
  box-shadow: 0px 3.424px 14.609px 0px rgba(0, 0, 0, 0.16);

  .stars_icon_container {
    margin-bottom: 11px;
  }

  .reviews_item_title {
    margin-bottom: 7px;
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 14.787px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .reviews_item_description {
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 14.787px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
}